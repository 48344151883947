<template>
    <div class="main">
        <div class="container">
            <div class="trxTitle productListTitle">
                <h1>{{ activeLanguage.createCombinedProduct }}</h1>
            </div>
            <div class="trxTitle">
                <p class="trxProductListTableP">{{ activeLanguage.transactionIntro }}</p>
            </div>

            <div class="trxMainTables col-10">
                <div class="trxTable">
                    <div class="trxTableMain" style="border-top: none;">
                        <div class="row col-12" style="margin-bottom: 10px; margin-right: 25px;">
                            <div class="col-6 transaction">
                                <label for="transactionName">{{activeLanguage.transactionName}}*</label>
                                <input type="text" class="form-control" v-model="transactionName" :placeholder="activeLanguage.transactionName" minlength="3" required>
                            </div>
                        </div>
                        <div class="row col-12">
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-7">
                                        <label for="products">{{activeLanguage.selectProducts}}*</label>
                                    </div>
                                    <div class="col-3" style="padding-left: 0">
                                        <label for="quantity">{{activeLanguage.quantity}}*</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <template v-for="(product, index) in products">
                                    <div class="input-group col-7" style="margin-bottom: 10px;">
                                        <ProductItemInput
                                            :product="product"
                                            :notFound="activeLanguage.notFound"
                                            @setProduct="setProduct($event, index)"
                                        />
                                    </div>
                                    <input type="text" class="form-control col-3" v-model="product.quantity" :placeholder="activeLanguage.quantity" style="height: fit-content;" required @change="checkRequirements(product)"/>
                                    <div class="input-group-addon" @click="removeProduct(index)">
                                        <i class="fas fa-trash" aria-hidden="true" style="color: red; margin-left: 5px;"></i>
                                    </div>
                                </template>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 row addMore" @click="addProduct">
                            <div class="input-group-addon" v-if="showAddBtn">
                                <i class="fas fa-plus" aria-hidden="true"></i>
                            </div>
                            <p class="addMore-text">{{this.addBtnText}}</p>
                        </div>
                        <div class="row col-12" style="margin-bottom: 10px;">
                            <div class="col-2 addTrx" style="margin-left: auto;">
                                <button class="btn productListTitleBtn" @click="onAddTrx">{{activeLanguage.addTrx}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductItemInput from '../../components/trxView/productItemInput.vue'

export default {
    name: 'TrxCreateTransaction',
    data() {
        return {
            transactionName: '',
                products : [
                    {
                        name: '',
                        quantity: null
                    }
                ],
                productNames: [],
                selectedProducts: [
                    {
                        id: null,
                        quantity: null
                    }
                ],
                addBtnText: '',
                showAddBtn: true,
                disableAddTrx: true
        }
    },
    mounted() {
        this.addBtnText = this.activeLanguage.addMore
    },
    methods: {
        setProduct(product, index){
                this.products[index].id = product.id
                this.checkRequirements(this.products[index])
            },
            resetDefaults(){
                this.products = [
                    {
                        name: '',
                        quantity: 0
                    }
                ]
                this.productNames = []
                this.selectedProducts = [
                    {
                        id: null,
                        quantity: 0
                    }
                ]
                this.transactionName = ''
            },
            onAddTrx(){
                this.products.map((item, index) => {
                    this.selectedProducts[index].id = item.id
                    this.selectedProducts[index].quantity = parseInt(item.quantity)
                })

                let params = {
                    name: this.transactionName,
                    products : this.selectedProducts
                }

                orderAxios
                    .post(`transaction/add`, params )
                    .then(({ data }) => {
                        EventBus.$emit('showAlertModal', data.message)
                        this.goToTrxList()
                        this.resetDefaults()
                        this.$forceUpdate()
                    })
                    .catch(error => {
                        console.error(error)
                        EventBus.$emit('showAlertModal', error.error)
                    })
                
            },
            addProduct() {
                let defProduct = {
                    name: '',
                    quantity: 0,
                    id: null
                }
                if (this.products.length <= 9) {
                    this.products.push(defProduct)
                    this.selectedProducts.push({
                            id: null,
                            quantity: 0
                        })
                    this.addBtnText = this.activeLanguage.addMore
                    this.showAddBtn = true
                } else if (this.products.length >= 10) {
                    this.addBtnText = this.activeLanguage.productsLimit
                    this.showAddBtn = false
                }
                this.$forceUpdate();
            },
            removeProduct(index) {
                if(this.products.length !== 1) {
                    this.products.splice(index, 1)
                }
                if(this.selectedProducts.length !== 1) {
                    this.selectedProducts.splice(index, 1)
                }
                if (this.products.length < 10) {
                    this.addBtnText = this.activeLanguage.addMore
                    this.showAddBtn = true
                }
            },
            checkRequirements(product) {
                switch(true) {
                        case !product.quantity :
                            this.disableAddTrx = true
                        case this.transactionName === '' :
                            this.disableAddTrx = true
                        case !product.name === '' :
                            this.disableAddTrx = true
                        case product.showNotFoundText :
                            this.disableAddTrx = true
                        case !product.id :
                            this.disableAddTrx = true
                            break;
                        default:
                            this.disableAddTrx = false
                    }
            },
            goToTrxList() {
                this.$router.push('/trxtransactionlist')
            }
    },
    watch: {
            products: function (newVal, oldVal) {
                if(newVal !== oldVal) {
                    if ( newVal.length <= 9) {
                        this.addBtnText = this.activeLanguage.addMore
                        this.showAddBtn = true
                    } else if (newVal.length >= 10) {
                        this.addBtnText = this.activeLanguage.productsLimit
                        this.showAddBtn = false
                    }
                    newVal.map(product => {
                        this.checkRequirements(product)
                    })
                }
            },
            transactionName: function (newVal, oldVal) {
                if(newVal !== oldVal) {
                    this.products.map(product => {
                    this.checkRequirements(product)
                })
                }
            }
        },
    computed: {
        ...mapGetters({
            activeLanguage: 'getTrxLanguage'
        })
    },
    components: {
        ProductItemInput
    }
}

</script>

<style>
    .logoTitle {
        display: flex;
        justify-content: center;
    }
    .logoField {
        /* width: 200px; */
        /* height: 200px; */
        width: 50%;
        height: 50%;
        max-height: 200px;
        /* border-radius: 10px; */
        /* background-color: #DBDADD; */
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .logoInner {
        margin-top: auto;
        margin-bottom: auto;
    }
    .logoImg {
        max-width: 100%;
        max-height: 200px;
        /* border-radius: 10px; */
        margin: auto;
        width: auto;
        height: auto;
        object-fit: cover;
    }
    .customFileInput {
        /* width: 200px; */
        height: 200px;
        position: absolute;
        opacity: 0;
    }
    .image {
        display: flex;
    }
    .deleteLogo {
        color: #d8271a;
        z-index: 20;
        position: absolute;
        padding: 7px;
        margin-left: 310px;
        margin-top: -15px;
    }
    .logoField {
        width: 150px;
        height: 150px;
        max-height: 200px;
        /* border-radius: 10px; */
        /* background-color: #DBDADD; */
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .logoInner {
        margin-top: auto;
        margin-bottom: auto;
    }
    .logoImg {
        max-width: 100%;
        max-height: 200px;
        /* border-radius: 10px; */
        margin: auto;
        width: auto;
        height: auto;
        object-fit: cover;
    }
    .img-responsive {
        /* margin-top: 20px; */
        margin-bottom: auto;
        /* margin-left: 20px; */
        margin-right: auto;
    }
</style>

<style scoped>
 .autocompleteProduct {
    width: 100%;
 }
</style>